@import '@/scss/import.scss';

.layout {
  display: grid;
  grid-template-columns: 4.5fr 5.5fr;
  height: 100vh;
  overflow: hidden;

  @include respondMax('tablet') {
    display: block;
  }

  &_left {
    background: $col-primary;
    color: $col-white;

    display: grid;
    grid-template-rows: 1.125fr 2fr;

    @include respondMax('tablet') {
      display: none;
    }
    & > header {
      margin: 10rem 0 30px 10rem;
      a {
        @extend %text-56;
        color: $col-white;
        margin-bottom: 1rem;
      }
      p {
        @extend %text-20;
        font-weight: 500;
      }
    }

    div {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.layout_right {
  display: grid;
  place-items: center;
  overflow: auto;
  height: 100vh;

  header {
    h1 {
      @extend %text-32;
      letter-spacing: -0.006em;
      font-weight: 700;
      color: $col-neutral-800;
      text-align: center;
      margin-bottom: 3rem;
    }
    a,
    button {
      @extend %center;
      @extend %text-16;
      font-weight: 600;
      color: $col-neutral-700;
      margin-bottom: max(12px, 2rem);
      width: 100%;
      height: max(5.6rem, 48px);
      border: 1px solid $col-neutral-500;
      border-radius: 1.2rem;
      span {
        display: flex;
        align-items: flex-end;
        gap: max(12px, 1.5rem);
        min-width: 110px;
        img {
          margin-bottom: -3px;
        }
      }
    }
    p {
      @extend %text-16;
      @extend %flex-ac;
      margin: 3rem 0;
      font-weight: 600;
      gap: 3rem;
      span {
        display: block;
        height: 1px;
        width: 100%;
        background: $col-neutral-500;
      }
      color: $col-neutral-600;
    }
    h4 {
      @extend %text-20;
      text-align: center;
      margin-top: -2rem;
    }
  }

  section {
    width: 65%;
    padding-block: max(4rem, 20px);
    @include respondMax('tablet2x') {
      width: 85%;
    }
    @include respondMax('mobile') {
      width: 90%;
    }
  }

  form {
    padding-bottom: max(40px, 6rem);
    & > a {
      margin-top: max(15px, 1.5rem);
      color: $col-primary;
      @extend %link;
      @extend %text-16;
    }

    button {
      margin-top: max(25px, 4rem);
      margin-bottom: max(15px, 2rem);
    }

    aside {
      text-align: center;
      @extend %text-16;
      text-align: center;
      a {
        @extend %link--hidden;
      }
    }
  }

  footer {
    @extend %text-14;
    display: flex;
    align-items: flex-start;
    // text-align: center;
    margin-top: max(20px, 3rem);
    line-height: max(16px, 2.4rem);
    gap: max(0.5rem, 5px);
    label {
      margin-top: max(0.5rem, 5px);
    }
    a {
      @extend %link--hidden;

      &:after {
        bottom: 4px;
      }
    }
  }
}

.layoutMb {
  &_head {
    @extend %container;
    margin-right: auto;
    padding-top: 30px;
    display: none;

    @include respondMax('tablet') {
      display: block;
    }
    span {
      @extend %text-24;

      color: $col-neutral-800;
    }

    p {
      margin-top: 2px !important;
      margin-bottom: 30px !important;
      font-size: max(1.5rem, 10px) !important;
    }

    img {
      margin-inline: auto;
      display: block;
      width: max(12rem, 80px);
      height: max(12rem, 80px);
    }
  }
}
